*{
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #0f0110;
    color: #bebabe;
}

/* .app-contain{
    overflow-y: hidden;
} */

/* .menu-contain{
    height: 16vh;
}

.content-contain{
    height: 79vh !important;
    overflow-y: auto;
}
.copy-contain{
    height: 5vh !important;
} */

.MenuBar{
    display: flex;
    justify-content: center;
}

.menu-tab-select{
    cursor: pointer;
    text-decoration: none;
    color: #bebabe;
}

.menu-tab-select:hover{
    color: #9818f3;
}


.home-intro{
    display: grid;
    align-content: center;
}



::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(240, 134, 213);
    border-radius: 0px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #9818f38b;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #9818f38b;
  }
  


@media screen and (max-width: 991px) {
    .MenuBar{
        display: flex;
        justify-content: start;
        padding-left: 10px;
    }
    .home-intro{
        margin-left: -50px;
    }
}


/*.hello-box{*/
/*    border-bottom: 3px solid #9818f3;*/
/*}*/

.hello-text{
    color: #9818f3;
    font-weight: bold;
    font-size: 350%;
    border-bottom: 3px solid #bebabe;
}

.home-description{
    color: #bebabe;
    font-size: 110%;

}

/************ Graphics Page **************/

#photos {
    line-height: 0;
    -webkit-column-count: 5;
    -webkit-column-gap:   0px;
    -moz-column-count:    5;
    -moz-column-gap:      0px;
    column-count:         5;
    column-gap:           0px;
    /*display: flex;*/
    align-items: center;
}

.image-map{
    transition:0.3s;
}

.image-map:hover{
    transform: scale(1.027);
    transition: 0.3s;}

#photos img {
    width: 100% !important;
    height: auto !important;
    padding: 5px;
}

@media (max-width: 1200px) {
    #photos {
        -moz-column-count:    4;
        -webkit-column-count: 4;
        column-count:         4;
    }
}
@media (max-width: 1000px) {
    #photos {
        -moz-column-count:    3;
        -webkit-column-count: 3;
        column-count:         3;
    }
}
@media (max-width: 800px) {
    #photos {
        -moz-column-count:    2;
        -webkit-column-count: 2;
        column-count:         2;
    }
}
@media (max-width: 400px) {
    #photos {
        -moz-column-count:    1;
        -webkit-column-count: 1;
        column-count:         1;
    }
}

.abt-tab-col{
    box-shadow:1px 1px 3px #bebabe;
    border-radius: 10px;
    transition: 0.2s;
}

.abt-tab-col:hover{
    box-shadow:1px 2px 3px #9818f3;
    transform: scale(1.01);
    transition: 0.2s;
}

.abt-tab{
    border-bottom: 3px solid #9818f3;
}

.abt-tab h4 {
    font-weight: bold;
}

.visit-website-btn{
    background-color: #9818f3;
    color: white;
    border: none;
    border-radius: 20px;
    transition: 0.2s;
}

.visit-website-btn a{
    text-decoration: none;
    color: #bebabe;
}

.visit-website-btn:hover{
    transition: 0.2s;
    transform: scale(1.04);
    background-color: #9818f3;
    color: white;
}

.visit-website-btn a:hover{
    color: white;
}

.exp-logo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.rec-logo{
    /*display: flex;*/
    align-items: center;
    justify-content: center;
}

.exp-link{
    transition: 0.2s;
}

.exp-link:hover{
    transform: scale(1.07);
    transition: 0.2s;
}

.iframe-container{
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
}
.iframe-container iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

#skills {
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap:   0;
    -moz-column-count:    3;
    -moz-column-gap:      0;
    column-count:         3;
    column-gap:           0;
    /*display: flex;*/
    align-items: center;
}

.skill-map{
    transition:0.3s;
}

.skill-map:hover{
    transform: scale(1.027);
    transition: 0.3s;}

#skills div {
    width: 100% !important;
    height: auto !important;
    padding: 5px;
}

@media (max-width: 1200px) {
    #skills {
        -moz-column-count:    3;
        -webkit-column-count: 3;
        column-count:         3;
    }
}
@media (max-width: 1000px) {
    #skills {
        -moz-column-count:    3;
        -webkit-column-count: 3;
        column-count:         3;
    }
}
@media (max-width: 800px) {
    #skills {
        -moz-column-count:    2;
        -webkit-column-count: 2;
        column-count:         2;
    }
}
@media (max-width: 400px) {
    #skills {
        -moz-column-count:    1;
        -webkit-column-count: 1;
        column-count:         1;
    }
}


@media screen and (max-width: 1441px) {
    .hello-text{
        font-size: 265%;
    }

    .home-description{
        font-size: 100%;
    }
}

@media screen and (max-width: 1412px) {
    .hello-text{
        font-size: 235%;
    }
    .home-description{
        font-size: 100%;
    }
    .exp-link img {
        width: 110%;
    }
    .hello-comma img{
        width: 45%;
    }
}

@media screen and (max-width: 1190px) {
    .hello-text{
        font-size: 200%;
    }

    .exp-link img {
        width: 120%;
    }

    .home-description{
        font-size: 90%;
    }
}

@media screen and (max-width: 1095px) {
    .hello-text{
        font-size: 200%;
    }

    .hello-img img {
        width: 90%;
    }

    .home-description{
        font-size: 90%;
    }
}



/*@media screen and (max-width: 986px) {*/
/*    .hello-text{*/
/*        font-size: 180%;*/
/*    }*/

/*    .exp-link img {*/
/*        width: 200%;*/
/*    }*/

/*    .home-description{*/
/*        font-size: 90%;*/
/*    }*/

/*    .copy-row{*/
/*        padding-top: 100px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 854px) {*/
/*    .home-row{*/
/*        padding-top: 50px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 823px) {*/
/*    .home-row{*/
/*        padding-top: 50px;*/
/*    }*/
/*}*/

@media screen and (max-width: 991px) {
    .hello-comma img{
        width: 40%;
    }
    .home-intro{
        position: unset;
    }
    .hello-img{
        position: relative;

    }
    .hello-img img{
        width: 55%;
        margin-top: -100px;
    }

    .home-description label{
        margin-top: -100px;
    }
    .exp-link img {
        width: 60%;
    }
}

@media screen and (max-width: 792px) {
    .exp-link img {
        width: 80%;
    }
}

@media screen and (max-width: 767px) {
    .exp-link img {
        width: 100%;
    }
}

@media screen and (max-width: 669px) {
    /* .copy-row{
        padding-top: 40px;
    } */
    .logo img{
        width: 15%;
        padding: 20px 0;
    }
}
@media screen and (max-width: 604px) {
    .home-description label{
        padding-top: 70px;
    }
}

@media screen and (max-width: 576px) {
    .home-description label{
        padding-top: 100px;
    }
    .exp-link img {
        width: 140%;
    }
    .copy-row p{
        font-size: 12px;
    }
}

@media screen and (max-width: 500px) {
    .exp-link img {
        width: 180%;
    }
}

@media screen and (max-width: 472px) {
    .logo {
        display: none;
    }
    .MenuBar nav button{
        margin-top: 20px;
        margin-left: 20px;
    }
    .hello-img img{
        width: 55%;
        margin-top: -20px;
    }
    .exp-link img {
        width: 210%;
    }
}
@media screen and (max-width: 472px) {
    .exp-link img {
        width: 250%;
    }
    .hello-img img{
        width: 75%;
    }
}

@media screen and (max-width: 409px) {
    .exp-link img {
        width: 350%;
    }
    .hello-text{
        font-size: 170%;
    }
    .home-description label{
        font-size: 12px;
    }
}

@media screen and (max-width: 391px) {
    .exp-link img {
        width: 500%;
    }
}

@media screen and (max-width: 365px) {
    .exp-link img {
        width: 800%;
    }
}

@media screen and (max-width: 345px) {
    .exp-link img {
        width: 1000%;
    }
}

@media screen and (max-width: 768px) {
    .exp-logo img {
        width: 50%;
    }
}

@media screen and (max-width: 574px) {
    .exp-det h5{
        font-size: 14px;
    }
    .exp-det h6{
        font-size: 14px;
    }
    .exp-link{
        width: 120%;
    }
}

@media screen and (max-width: 354px){
    .exp-link{
        width: 160%;
    }
}

@media screen and (max-width: 354px){
    .exp-link img{
        width: 100%;
    }
    .my-links{
        padding: 20px;
        margin-left: 20px;
    }
}


/*******************************Skill Section **********************************/

.skill-bubble-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skill-bubble{
    background-color: #9818f3;
    color: white;
    border-radius: 2em;
    display:inline-block;
    cursor: default;
    transition: 0.2s;
    font-weight: 500;
}

.skill-bubble:hover{
    transform: scale(1.03);
    transition: 0.2s;
}